import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ScrollAnimation from 'react-animate-on-scroll'
import { GoPrimitiveDot } from 'react-icons/go'
import Title from './title'
import * as styles from '../../styles/css/whyus.module.css'

const WhyUs = () => {
  
  const { t } = useTranslation();
  const data = [
    {
        id: "1",
        title: t('whyusElement1Title'),
        items: [
          t('whyusElement1Item1'),
          t('whyusElement1Item2'),
          t('whyusElement1Item3')
        ]
    },
    {
        id: "2",
        title: t('whyusElement2Title'),
        items: [
          t('whyusElement2Item1'),
          t('whyusElement2Item2'),
          t('whyusElement2Item3')
        ]
    },
    {
        id: "3",
        title: t('whyusElement3Title'),
        items: [
          t('whyusElement3Item1'),
          t('whyusElement3Item2'),
          t('whyusElement3Item3')
        ]
    },
    {
        id: "4",
        title: t('whyusElement4Title'),
        items: [
          t('whyusElement4Item1'),
          t('whyusElement4Item2'),          
          t('whyusElement4Item3')          
        ]
    },
  ]
    return (
      <>                                 
        <div id="whyus" className="rwt-timeline-area section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Title
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {t('whyusSubtitle')}
                  title = "Why Us"
                  description={t('whyusBody')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--50">
                <div className={`timeline-style-two bg-color-blackest ${styles.box}`}>
                    {/* <div className='mt--100'>
                      <div> */}
                        <div className="row row--0">
                          {data.map((data , index) =>(
                            <div className={`col-lg-3 col-md-3 rn-timeline-single`} key={index}>                      
                              <div className="rn-timeline"> 
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className={`title${index == 0 ? ` ${styles.title}` : ''}`}>{data.title}</h6>
                                </ScrollAnimation>
                                <div className="progress-line">
                                    <div className="line-inner"></div>
                                </div>
                                <div className="progress-dot">
                                    <div className="dot-level">
                                        <div className="dot-inner"></div>
                                    </div>
                                </div>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>                                    
                                  <ul className={styles.list}>
                                  {data.items.map((item , i) =>(
                                    <li key={i}>{item}</li>
                                  ))}
                                  </ul>
                                </ScrollAnimation>
                              </div>
                            </div>
                          ))}
                        {/* </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default WhyUs;
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { FiMail , FiMapPin, FiMessageCircle } from "react-icons/fi";
import Title from './title'
import Map from '../contact/map'

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>      
      <div id="contact" className="rwt-contact-area section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb--40">
              <Title
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {t('contactSubtitle')}
                  title = "Contact"
                  description = {t('contactBody')}
              />
            </div>
          </div>
          <div className="row row--15">
            <div className="col-lg-12">
              <div className="rn-contact-address mt_dec--30">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-address">
                      <div className="icon">
                        <FiMessageCircle size="50" />
                      </div>
                      <div className="inner">
                        <h4 className="title">Chat with us</h4>
                        <p><a href="https://wa.me/821029067317" target="_blank">Whatsapp</a></p>
                        <p><a href="https://open.kakao.com/o/sEuQ3jee" target="_blank">KakaoTalk</a></p>
                        {/* <p>&nbsp;</p> */}
                        {/* <p><a href="tel:00821026619454" target="_blank">+82 10-2661-9454</a></p> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-address">
                      <div className="icon">
                        <FiMail size="50" />
                      </div>
                      <div className="inner">
                        <h4 className="title">Our Email Address</h4>
                        <p><a href="mailto:contact@alphacode.ai">contact@alphacode.ai</a></p>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="rn-address">
                      <div className="icon">
                        <FiMapPin size="50" />
                      </div>
                      <div className="inner">
                        <h4 className="title">Our Location</h4>
                        <p>{t('location1')}</p>
                        <p>{t('location2')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt--40 row--15">
            <div className="row">              
              <Map />
            </div>
          </div>
        </div>
      </div>            
    </>
  )
}

export default Contact
import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'
import Separator from '../components/separator'
import Home from '../components/section/home'
import Main from '../components/section/main'
import Service from '../components/section/service'
import WhyUs from '../components/section/whyus'
import Contrct from '../components/section/contact'

const Index = () => {
  const { t } = useTranslation();
  
  return (
    <Layout>
      <div className="main-content">
        <Home />

        <Separator />

        <Main />
        
        <Separator />
        
        <Service />

        <Separator />

        <WhyUs />

        <Separator />

        <Contrct />
        
        <Separator />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default Index

import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ScrollAnimation from 'react-animate-on-scroll'
import { FiCheck } from 'react-icons/fi'
import * as styles from '../../styles/css/main.module.css'

const Main = () => {
  const { t } = useTranslation();
    return (
      <>                                 
        <div className="rwt-about-area about-style-2 section-gap">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                    <img className="w-100 radius-30" src="https://storage.googleapis.com/alphacode-company-image/main.jpg" alt="Main Images" />
                </div>
              </div>

              <div className="col-lg-7 mt_md--30 mt_sm--30">
                <div className="content">
                  <div className="section-title">

                      <ScrollAnimation 
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}>
                          <h2 className="title mt--10">{t('mainTitle')}</h2>
                      </ScrollAnimation>

                      <ScrollAnimation 
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}>
                        <p className={styles.desc}>{t('mainBody')}</p>

                        <ul className="list-icon">
                          <li className={styles.item}><span className="icon"><FiCheck /></span> {t('mainElement1')} </li>
                          <li className={styles.item}><span className="icon"><FiCheck /></span> {t('mainElement2')} </li>                          
                        </ul>
                      </ScrollAnimation>

                      <ScrollAnimation 
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}>
                        <div className="read-more-btn mt--40">
                          <p className={styles.desc}>{t('mainDesc')}</p>
                        </div>
                      </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Main;
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import * as styles from '../../styles/css/home.module.css'

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="slider-area slider-style-1 bg-transparent pb--90">  
        <div className={`row  ${styles.normal}`}>
          <div className={styles.video}>
            <video src="https://storage.googleapis.com/alphacode-company-video/home-1.mp4" muted autoPlay playsInline loop className={styles.bg} poster="/images/home/home.png"></video>
          </div>
          <div className={styles.section}>
            <ul className={styles.list}>
              <li className={styles.item}>
                <h1 className={styles.title}>{t('title')}</h1>
                <p className={styles.content}>{t('homeBody')}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className={`row ${styles.mobile}`}>
          <img src="/images/home/mobile1.jpg" />
          <div className={styles.section}>
            <ul className={styles.list}>
              <li className={styles.item}>
                <h1 className={styles.title}>{t('title')}</h1>
                <p className={styles.content}>{t('homeBody')}</p>
              </li>
            </ul>
          </div>
        </div>        
      </div>    
    </>
  )
}

export default Home;